import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";

interface ResultHolderProps {
  type: Object;
  data: Object[];
  filter: Function;
}
interface ResultHolderState {
  initialAttachments: number[];
  attachments: number[];
}

const ResultHolder: React.FC<{
  attach: Function;
  detach: Function;
  addButton?: React.FC;
  attachedData: Array<any>;
  type: any;
  data: any;
  filter: any;
  setOpen: Function;
  setModalOpen: Function;
  refetch?: Function;
  id?: string;
}> = ({
  attach, detach, addButton, refetch, id, ...props 
}) => {
  const initial = props.attachedData.map((d) => d.target);
  const [loading, setLoading] = useState(false)
  const [currentId, setCurrentID] = useState([] as any);
  const { type, data, filter } = props;

  const handleAttachClick = async(e,d) => {
    setLoading(true)
    setCurrentID((oldArray) => [...oldArray, e.target.id]);
    await attach(type.field(d));
    if(refetch){
      await refetch();
    }
    setCurrentID(
      (currentId)=> {
        return currentId?.filter(a =>
          a !== e.target.id,
        )
      },
    );
    setLoading(false);
  }

  const handleDetachClick = async(e,d) => {
    setLoading(true)
    setCurrentID((oldArray) => [...oldArray, e.target.id])
    await detach(type.field(d));
    if(refetch){
      await refetch();
    }
    setCurrentID(
      (currentId)=> {
        return currentId?.filter(a =>
          a !== e.target.id,
        )
      },
    );
    setLoading(false);
    
  }
  // show controls value
  return (
    <div
      style={{
        height: "auto",
        padding: "0",
        maxHeight: "150px",
        overflowY: "scroll",
      }}
    >
      {data
        ?.filter(
          (entity) =>
            !filter
              || entity?.__typename === "Controls" ? (entity?.displayID?.toLowerCase().indexOf(filter?.toLowerCase()) > -1 || entity?.title?.toLowerCase().indexOf(filter?.toLowerCase()) > -1) : entity.title?.toLowerCase().indexOf(filter?.toLowerCase()) > -1,
        ).filter((entity)=> !(!initial.some((a) => a === entity.id) && entity?.archived))
        .map((d) => {
          const attached = d?.__typename === "Controls" ? initial.some((a) => a === d?.uuid) : initial.some((a) => a === d.id);
          return (
            
            <div className="form-check" key="resultMap">
              <div className="col-md-10 pl-0 listresult">
                {d?.__typename === "Controls" ? <> 
{' '}
<span className="font-weight-bold">
{d?.displayID}
{' '}
</span>
{' '}
:
{' '}
{d?.title}
{' '}
                                                </> : <>
{' '}
{type.format(d)}
{d.archived && "(archived)"}
                                                      </>}
                {(!attached && (
                  <Button
                    variant="link"
                    id={d?.__typename !== "Controls" ? d.id : d.uuid}
                    onClick={async(e) => {
                      await handleAttachClick(e,d);
                    }}
                    className="p0 float-right"
                  >
{ loading === false ? "Add" : ((d?.__typename !== "Controls" ? currentId?.includes(d.id) : currentId?.includes(d.uuid)) ? <Spinner size="sm" animation="border" variant="primary" /> : "Add") }
                  </Button>
                )) || (
                    <Button
                      variant="link"
                      id={d?.__typename !== "Controls" ? d.id : d.uuid}
                      onClick={async(e) => {
                        await handleDetachClick(e,d)
                      }}
                      className="p0 float-right"
                    >
                     { loading === false ? "Remove" : ((d?.__typename !== "Controls" ? currentId?.includes(d.id) : currentId?.includes(d.uuid)) ? <Spinner size="sm" animation="border" variant="primary" /> : "Remove") } 
                    </Button>
                )}
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default ResultHolder;
