import React from "react";
import _ from "lodash";
import * as d3 from "d3";
import { ProgressBar } from "../Visualizations/ProgressBar";
import { Priority, Severity } from "API";
import { DateTime } from "luxon";
import { formatPriority, formatSeverity, formatStatus, mapStatus } from "utils/formatStatus";
import "./Stats.scss";

export const Dates = ({ setFilter, ...props }) => {
  const today = DateTime.local().startOf("day");
  const thisMonth = today.plus({ months: 1 });
  const nextMonth = today.plus({ months: 2 });
  const field = (d) => {
    const field = d.dueDate;
    if (!field) return null;
    const due = DateTime.fromFormat(field, "MM/dd/yyyy");

    if (due < today) {
      return "Past Due";
    }
    if (due < thisMonth) {
      return "This Month";
    }
    if (due < nextMonth) {
      return "Next Month";
    }
    return null;
  };
  return (
    <Stats
      {...{
        ...props,
        ...({
          field,
          disabledTooltip: (d) =>
            "Due Date column must be enabled to filter by this field",
          fieldMask: ["Past Due", "This Month", "Next Month"],
          colors: ["#d83648", "#ee8219", "#007ac3"],
        } as any),
      }}
    />
  );
};

export const StatusGraph = ({ type, ...props }) => {
  const _fieldMask = Object.values(type);

  const fieldMask = _fieldMask;

  return (
    <Stats
      data={props.data}
      onClick={props.onClick}
      {...{
        ...props,
        field: (d) => {
          if (d.status !== "Pending")
            return mapStatus(d.status);
          return d.status
        },
        fieldMask,
        useClass: true,
        disabledTooltip: (d) =>
          "Status column must be enabled to filter by this field",
      }}
    />
  );
};

export const PriorityGraph = (props) => {
  const fieldMask = Object.values(Priority).map(formatPriority);

  return (
    <Stats
      {...{
        ...props,
        field: (d) => formatPriority(d.priority),
        fieldMask,
        useClass: true,
        disabledTooltip: (d) =>
          "Priority column must be enabled to filter by this field",
      }}
    />
  );
};

export const SeverityGraph = (props) => {
  const fieldMask = Object.values(Severity).map(formatSeverity);

  return (
    <Stats
      {...{
        ...props,
        field: (d) => formatSeverity(d.severity),
        fieldMask,
        useClass: true,
        disabledTooltip: (d) =>
          "Severity column must be enabled to filter by this field",
      }}
    />
  );
};

const colorSet = [
  "#007ac3",
  "#0c8a4a",
  "#ee8219",
  "#d83648",
  "#7300c1",
  "#ca0081",
];
const parseField = (data, field) => {
  if (field) {
    if (Array.isArray(field)) {
      return field
        .map((f) => data.map(f))
        .reduce((a, b) => [...a, ...b], []);
    }
    return data.map(field);
  }
  return data;
};
export const Stats = ({
  data,
  field,
  onClick,
  fieldMask,
  colors = colorSet,
  useClass = false,
  disabledTooltip,
}) => {
  const dataSet = parseField(data, field);
  const group = _.groupBy(dataSet);
  const keys = fieldMask || Object.keys(group);
  const formatted = keys.map((key) => ({
    value: (group[key] && group[key].length) || 0,
    name: key,
  }));
 
  const max = d3.max(formatted.filter(d => d.name !== "Closed").map((d) => d.value));
  return (
    <>
      {formatted.map((d, i) => {
        const clickHandler = (typeof onClick === "function" && {
          onClick: () => onClick(formatStatus(d.name)),
        })
          || (typeof onClick === "object"
            && onClick[d.name] && {
            onClick: () => onClick[d.name](formatStatus(d.name)),
          })
          || {};
        return (
          <>
            <div>
              <label>{formatStatus(d.name)}</label>
              <ProgressBar
                data={[d]}
                valueField={(v) => v.value}
                max={max}
                color={colors?.[i] || undefined}
                useClass={useClass}
                disabledTooltip={disabledTooltip}
                className={d.name !== 'Pending' ? `stats-bar ${d.name}` : `stats-bar Created`}
                {...clickHandler}
              />
            </div>
            <br />
          </>
        );
      })}
    </>
  );
};

export default Stats;
