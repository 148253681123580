import React from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";

export const RelatedItemBadge = ({ items, display, filter = "" }) => {
  if (filter !== "") items = items.filter((a) => a.targetType === filter);
  if (display === "issue") {
    return (
      <>
        {items.map((item) => {
          return (
            <Link to={`/${filter}/${item.item.id}`}>
              <Badge
                pill
                className={`${item.item.status} releted_item`}
                title={`${item.item.title} (${itemStatus(
                  `${item.item.status}`
                )})`}
              >
                {item.item.title.length > 30
                  ? `${item.item.title.substring(0, 30 - 3)}...`
                  : item.item.title}
              </Badge>
            </Link>
          );
        })}
      </>
    );
  } else if (display === "task") {
    return (
      <>
        {items.map((item) => {
          return (
            <Link to={`/actionplans/${item.id}/tasks/${item.task.id}`}>
              <Badge
                pill
                className={`${item.task.status} releted_item`}
                title={`${item.task.title} (${itemStatus(
                  `${item.task.status}`
                )})`}
              >
                {item.task.title.length > 30
                  ? `${item.task.title.substring(0, 30 - 3)}...`
                  : item.task.title}
              </Badge>
            </Link>
          );
        })}
      </>
    );
  }
  return null;
};

const itemStatus = (status) => {
  let title = "";
  switch (status) {
    case "In_Progress":
      title = "In Progress";
      break;
    case "Closed_Pending_Approval":
      title = "Closed (Pending Approval)";
      break;
    default:
      title = status;
      break;
  }
  return title;
};
