import { UpdateAuditPlanningInput } from "API";
import React, { useEffect, useMemo, useState } from "react";
import { useAuditPlanContext } from "./AuditPlanningPage";
import { usePermissions } from "common/Permissions";
import { useParams } from "react-router";
import * as yup from "yup";
import { Card, Col, Row } from "react-bootstrap";
import { HGForm, HGToggleSubmit } from "common/HGForm";
import { HGRichText } from "common/HGForm/HGRichText";
import {
  defaultPreliminaryAuditWork,
  resolveIdToStringFn,
} from "../AuditPlanningInputs";
import { HGCheckBoxGroup } from "common/HGForm/Inputs";

const AuditPlanningPageScope = () => {
  const { id } = useParams<{ id: string }>();
  const { group } = usePermissions();
  const { auditPlan, updateAuditPlan, viewMode } = useAuditPlanContext();
  const [_preAuditWork, _setPreAuditWork] = useState(
    defaultPreliminaryAuditWork
  );
  const auditPlanScopeSchema = useMemo(
    () =>
      auditPlan &&
      yup.object<Partial<UpdateAuditPlanningInput>>({
        background: yup.string().default("").label("Background"),
        scope: yup.string().default("").label("Scope"),
        objective: yup.string().label("Objective"),
        methodology: yup.string().default("").label("Methodology"),
        points: yup.string().default("").label("Examination Details"),
        preliminaryAuditWork: yup
          .string()
          .default("")
          .label("Planning Checklist"),
      }),
    [auditPlan]
  );
  const loadedPreAuditWork = auditPlan?.preliminaryAuditWork;

  useEffect(() => {
    if (!loadedPreAuditWork) return;
    _setPreAuditWork({
      ...defaultPreliminaryAuditWork,
      ...JSON.parse(loadedPreAuditWork),
    });
  }, [loadedPreAuditWork]);
  return (
    <HGForm
      key="form_editAuditPlanningScope"
      onSubmit={async (input) => {
        return await updateAuditPlan({
          variables: {
            input: {
              id,
              groupID: group.id,
              ...input,
              preliminaryAuditWork: JSON.stringify(_preAuditWork),
            },
          },
        });
      }}
      resolver={auditPlanScopeSchema}
      editToggle
    >
      <Card>
        <Card.Header>
          <HGToggleSubmit className="float-right" />
          Scope and Objectives
        </Card.Header>
        <Card.Body>
          <Row>
            {viewMode === "advanced" && (
              <>
                <Col sm={12}>
                  <HGRichText
                    label="Background"
                    name="background"
                    defaultValue={auditPlan?.background}
                  />
                </Col>
                <Col sm={12}>
                  <HGRichText
                    label="Scope"
                    name="scope"
                    defaultValue={auditPlan?.scope}
                  />
                </Col>
              </>
            )}
            <Col sm={12}>
              <HGRichText
                label="Objective"
                name="objective"
                defaultValue={auditPlan?.objective}
              />
            </Col>
            {viewMode === "advanced" && (
              <>
                <Col sm={12}>
                  <HGRichText
                    label="Methodology"
                    name="methodology"
                    defaultValue={auditPlan?.methodology}
                  />
                </Col>
                <Col sm={12} className="mt-3">
                  <HGRichText
                    label="Examination Details"
                    name="points"
                    defaultValue={auditPlan?.points}
                  />
                </Col>
                <Col sm={12}>
                  <HGCheckBoxGroup
                    label="Planning Checklist"
                    name="preliminaryAuditWork"
                    enumObj={_preAuditWork}
                    resolveIdToStringFn={resolveIdToStringFn}
                    setEnumObj={_setPreAuditWork}
                    idPrefix={"customPreAuditCheckbox"}
                  />
                </Col>
              </>
            )}
          </Row>
        </Card.Body>
      </Card>
    </HGForm>
  );
};

export default AuditPlanningPageScope;
