import React, { useState, useEffect } from "react";
import { Col, Form, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useFormContext } from "react-hook-form";
import { useEditContext } from "./HGFormContext";
import { DateTime } from "luxon";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import "./HGDatePicker.css";
import { HGHidden } from "./Inputs";

export const HGDatePicker: React.FC<{
  name: string;
  label?: string;
  defaultValue?: string | null;
  min?: string | null;
  minFieldValue?: string | null;
  max?: string | null;
  maxFieldValue?: string | null;
  required?: boolean;
}> = ({
  name,
  label = "",
  defaultValue,
  required,
  min,
  minFieldValue,
  max,
  maxFieldValue,
}) => {
  const methods = useFormContext();
  const { register, setValue, getValues, errors } = methods;
  const _error = errors?.[name];
  const { editing } = useEditContext();
  const [date, setDate] = useState<Date | null>();
  const formValues = getValues();
  useEffect(() => {
    if (date && DateTime.fromJSDate(new Date(date)).isValid) {
      setValue(
        name,
        date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      );
    } else {
      setValue(name, null);
    }
  }, [date]);
  useEffect(() => {
    if (
      defaultValue &&
      DateTime.fromFormat(defaultValue, "MM/dd/yyyy")?.isValid
    ) {
      setDate(new Date(defaultValue));
    }
  }, []);
  return (
    <Form.Group>
      {label && <Form.Label style={{ fontWeight: "bold" }}>{label}</Form.Label>}
      {(!editing && <p>{defaultValue}</p>) || (
        <>
          <DatePicker
            className="form-control"
            key={name}
            onChange={(date: Date | null) => setDate(date)}
            dateFormat="MM/dd/yyyy"
            maxDate={
              (max && new Date(max)) ||
              (maxFieldValue && new Date(formValues[`${maxFieldValue}`])) ||
              undefined
            }
            minDate={
              (min && new Date(min)) ||
              (minFieldValue && new Date(formValues[`${minFieldValue}`])) ||
              undefined
            }
            showMonthDropdown
            showYearDropdown
            selected={date}
            isClearable
            onKeyDown={(e) => {
              e.preventDefault();
            }}
          />
          <Form.Control
            style={{ display: "none" }}
            name={name}
            ref={register}
            required={required}
            defaultValue={defaultValue ?? ""}
            isInvalid={!!_error}
          />
        </>
      )}
      {editing && _error && (
        <Form.Control.Feedback type="invalid">
          {_error.message}
        </Form.Control.Feedback>
      )}
    </Form.Group>
  );
};
export const HGDateRangePicker: React.FC<{
  name: string;
  label?: string;
  range?: { Start: string; End: string };
}> = ({ name, range }) => {
  const [startDate, setStartDate] = useState<Date | null>();
  const [endDate, setEndDate] = useState<Date | null>();
  const methods = useFormContext();
  const { register, getValues, setValue } = methods;
  const dateValue = ["", ""];
  useEffect(() => {
    if (startDate) {
      dateValue?.splice(
        0,
        0,
        startDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      );
    }
    if (endDate) {
      dateValue?.splice(
        1,
        0,
        endDate.toLocaleDateString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })
      );
    }
    setValue(name, dateValue);
  }, [startDate, endDate]);
  useEffect(() => {
    if (range?.[0]) {
      setStartDate(new Date(range?.[0]));
    }
    if (range?.[1]) {
      setEndDate(new Date(range?.[1]));
    }
  }, []);
  return (
    <Form.Group>
      <Form.Label style={{ fontWeight: "bold" }}>{name}</Form.Label>
      <Row>
        <Col sm={6}>
          <DatePicker
            className="form-control"
            name={`${name}[0]`}
            key={name}
            dateFormat="MM/dd/yyyy"
            showMonthDropdown
            autoComplete="off"
            maxDate={endDate}
            showYearDropdown
            selected={startDate}
            onChange={(date) => {
              if (!Array.isArray(date)) {
                setStartDate(date);
              }
            }}
            isClearable
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            placeholderText="From"
          />
          <HGHidden name={`${name}[0]`} />
        </Col>

        <Col sm={6}>
          <DatePicker
            className="form-control"
            name={`${name}[1]`}
            key={name}
            dateFormat="MM/dd/yyyy"
            showMonthDropdown
            autoComplete="off"
            minDate={startDate}
            showYearDropdown
            selected={endDate}
            onChange={(date) => {
              if (!Array.isArray(date)) {
                setEndDate(date);
              }
            }}
            isClearable
            onKeyDown={(e) => {
              e.preventDefault();
            }}
            placeholderText="To"
          />
          <HGHidden name={`${name}[1]`} />
        </Col>
      </Row>
    </Form.Group>
  );
};
