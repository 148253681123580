import {
  Paragraph,
  ShadingType,
  Table,
  TableCell,
  TableLayoutType,
  TableRow,
  TextRun,
  WidthType,
} from "docx";
import { getCompositeId } from "./dataFormatters";
import { formatStatus } from "./formatStatus";

export const useGetGroupByRelatedItem = (items, groups) => {
  if (items) {
    const relatedItems = items
      .filter((item) => !!item.item)
      .map((item) => {
        return {
          ...(item.targetType !== "controls" && {
            department: groups?.find((i) => i.groupID === item.item.groupID)
              ?.groupName,
          }),
          ...(item.item?.compositeID && { ID: getCompositeId(item.item) }),
          targetType: item?.targetType,
          title:
            item.item?.title ||
            (item.targetType === "artifacts" &&
              item?.item?.fileName?.split(".", 1)[0]),
          ...(item.item?.status && { status: formatStatus(item.item?.status) }),
          ...(item.item?.priority && { priority: item.item?.priority }),
          ...(item.item?.severity && { severity: item.item?.severity }),
        };
      });

    return relatedItems.reduce((acc, obj) => {
      const key = obj.targetType;
      if (!acc[key]) {
        acc[key] = [];
      }
      delete obj.targetType;
      acc[key].push(obj);
      return acc;
    }, {});
  }
};

export const getTableHeader = (value) => {
  return Object.keys(value[0])?.map((element: any) => {
    return new TableCell({
      shading: {
        fill: "#2F5496",
      },
      children: [
        new Paragraph({
          children: [
            new TextRun({
              text: (element.charAt(0).toUpperCase() + element.slice(1))
                .replace(/([a-z])([A-Z])/g, "$1 $2")
                .trim(),
              bold: true,
              color: "#FFFFFF",
            }),
          ],
        }),
      ],
    });
  });
};

export const getTableCells = (item) => {
  return Object.values(item).map((ele: any) => {
    return new TableCell({
      children: [new Paragraph(ele)],
    });
  });
};

export const getTableDataRows = (value) => {
  return value.map((element) => {
    return new TableRow({
      children: getTableCells(element),
    });
  });
};

export const relatedItemsParse = (relatedItems) => {
  const modulesOrder = [
    "auditplanning",
    "assessmentresponse",
    "issues",
    "scenarios",
    "actionplans",
    "artifacts",
    "urls",
  ];
  const formattedRelatedItems = {};
  modulesOrder.forEach((key) => {
    if (relatedItems[key]) {
      formattedRelatedItems[key] = relatedItems[key];
    }
  });
  return Object.entries(formattedRelatedItems)
    .map(([key, value]: any) => {
      return [
        new Paragraph({
          children: [
            new TextRun({
              text: `${
                key === "actionplans"
                  ? "Action Plans"
                  : key === "assessmentresponse"
                  ? "Assessment Response"
                  : key === "auditplanning"
                  ? "Audits"
                  : (key.charAt(0).toUpperCase() + key.slice(1))
                      .replace(/([A-Z])/g, " $1")
                      .trim()
              }:`,
              bold: true,
              break: 1,
            }),
          ],
        }),
        new Table({
          width: {
            size: 100,
            type: WidthType.PERCENTAGE,
          },
          layout: TableLayoutType.FIXED,
          rows: [
            new TableRow({
              children: getTableHeader(value),
            }),
            ...getTableDataRows(value),
          ],
        }),
      ];
    })
    .flat(1);
};
