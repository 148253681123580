import React, { useCallback } from "react";
import { Col, Breadcrumb, Card, Row, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ErrorBoundary } from "App";
import { useTabbedTable } from "common/TabbedTable";
import {
  useGetAuditPlansInGroup,
  useGetDownloadAuditPlanObj,
} from "utils/connectors/auditPlanningConnectors";
import { useMutation, gql } from "@apollo/client";
import { modifyAuditPlanning } from "graphql/mutations";
import {
  ModifyAuditPlanningMutation,
  ModifyAuditPlanningMutationVariables,
} from "API";
import useSetTitle from "utils/useSetTitle";
import { sleep } from "utils/useSleep";
import { useAuditPlanningSchema } from "./auditPlanningSchema";
import Loader from "common/Loader";
import ToastifyQueue from "common/Overlays/ToastifyQueue";
import * as StatTypes from "common/Stats";
import { DateTime } from "luxon";
import { AuditStatus } from "utils/formatStatus";
import { CreateAuditPlanningTemplateButton } from "./CreateAuditPlanningTemplate";
import { useTabs } from "common/Tabs";
import { usePermissions } from "common/Permissions";
import { auditPlanningReportSchema } from "./auditPlanningReportSchema";
import HGAccordion from "common/BootStrapComponents/HGAccordion";

export const AuditPlanningList = () => {
  const history = useHistory();
  const { auditPlans, auditPlansTemplates, refetch, loading }: any =
    useGetAuditPlansInGroup();
  useSetTitle("Audit Planning");
  const { groupRole } = usePermissions();
  const now = DateTime.local();
  const [triggerAuditPlanUpdate] = useMutation<
    ModifyAuditPlanningMutation,
    ModifyAuditPlanningMutationVariables
  >(gql(modifyAuditPlanning), {
    onCompleted: (response) => {
      const isArchived = response?.ModifyAuditPlanning?.[0]?.archived;
      if (isArchived === true) {
        ToastifyQueue(`Audit Plan Archived Successfully`, "success");
      } else if (isArchived === false) {
        ToastifyQueue(`Audit Plan Unarchived Successfully`, "success");
      }
      sleep(500).then(refetch);
    },
  });

  const archiveAuditPlan = useCallback(
    (id: string, archived = true) => {
      triggerAuditPlanUpdate({
        variables: {
          input: [
            {
              id,
              archived,
            },
          ],
        },
      });
    },
    [triggerAuditPlanUpdate]
  );

  const tableSchema = useAuditPlanningSchema({
    archiveAuditPlan,
    userRole: groupRole,
  });

  const {
    TableUI: AuditPlanTableTemplateUI,
    ColumnSortFilterUI: AuditPlanTemplateSortUI,
  } = useTabbedTable(
    "auditPlanningColumnSortOrder",
    auditPlansTemplates,
    tableSchema,
    auditPlanningReportSchema
  );

  const {
    TableUI: AuditPlanTableUI,
    ColumnSortFilterUI: AuditPlanSortUI,
    filteredFormatting,
    activeData,
    setSearchEntry,
    setTab: setHeaderTab,
  } = useTabbedTable(
    "auditPlanningColumnSortOrder",
    auditPlans,
    tableSchema,
    auditPlanningReportSchema
  );

  const [tab, _setTab, TabUI] = useTabs([
    "Audit Plans",
    "Audit Plans Templates",
  ]);

  const TabElements =
    tab === "Audit Plans"
      ? { TableUI: AuditPlanTableUI, ColumnSortFilterUI: AuditPlanSortUI }
      : {
          TableUI: AuditPlanTableTemplateUI,
          ColumnSortFilterUI: AuditPlanTemplateSortUI,
        };

  return (
    (loading && <Loader />) || (
      <>
        <Row className="dashboard-header">
          <Col xs="12" className="pr-0">
            <Breadcrumb>
              <Breadcrumb.Item>Audit Planning</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col xs="12" className="pr-0">
            {groupRole !== "read" && (
              <span className="float-right mr-3">
                <Button
                  onClick={() => {
                    history.push(`/audits/create`);
                  }}
                >
                  Create Audit Plan / Summary
                </Button>
                <CreateAuditPlanningTemplateButton
                  auditPlanTemplates={auditPlansTemplates}
                />
              </span>
            )}

            <h3>All Audit Plans</h3>
          </Col>
        </Row>
        <HGAccordion module="auditPlanning">
          <Row>
            <Col xl={3} sm={12}>
              <Card>
                <Card.Header>Status</Card.Header>
                <Card.Body>
                  {(loading && <Loader />) || (
                    <StatTypes.StatusGraph
                      type={AuditStatus}
                      data={auditPlans.filter(
                        (auditPlan) => !auditPlan.archived
                      )}
                      onClick={
                        filteredFormatting.some((d) => d.name === "Status") &&
                        ((name) => {
                          if (name === "Closed") {
                            setHeaderTab("Closed");
                          } else if (name === "Closed (Pending Approval)") {
                            setHeaderTab("Closed (Pending Approval)");
                          } else {
                            setHeaderTab("Active");
                          }
                          setSearchEntry("Status", name);
                        })
                      }
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
            <Col xl={3} sm={12}>
              <Card>
                <Card.Header>Workflow Status KPI</Card.Header>
                <Card.Body>
                  {(loading && <Loader />) || (
                    <StatTypes.Stats
                      data={activeData}
                      onClick={{
                        "Need to Start":
                          filteredFormatting.some(
                            (d) => d.name === "Audit Start Date"
                          ) &&
                          ((d) => {
                            setSearchEntry("Audit Start Date", [
                              "",
                              now.toFormat("MM/dd/yyyy"),
                            ]);
                            setSearchEntry("Audit End Date", ["", ""]);
                            setSearchEntry("Status", "Created");
                          }),
                        Current:
                          filteredFormatting.some(
                            (d) => d.name == "Audit Start Date"
                          ) &&
                          ((d) => {
                            setSearchEntry("Audit Start Date", [
                              "",
                              now.toFormat("MM/dd/yyyy"),
                            ]);
                            setSearchEntry("Audit End Date", [
                              now.toFormat("MM/dd/yyyy"),
                              "",
                            ]);
                            setSearchEntry("Status", "");
                          }),
                        "Past Due":
                          filteredFormatting.some(
                            (d) => d.name == "Audit End Date"
                          ) &&
                          ((d) => {
                            setSearchEntry("Audit Start Date", ["", ""]);
                            setSearchEntry("Audit End Date", [
                              "",
                              now.toFormat("MM/dd/yyyy"),
                            ]);
                          }),
                      }}
                      field={[
                        (d) => {
                          const auditStartDate = DateTime.fromFormat(
                            d.auditStartDate,
                            "MM/dd/yyyy"
                          );
                          if (d.status !== "Pending") {
                            return null;
                          }
                          if (auditStartDate < now && d.status === "Pending") {
                            return "Need to Start";
                          }
                        },
                        (d) => {
                          const auditStartDate = DateTime.fromFormat(
                            d.auditStartDate,
                            "MM/dd/yyyy"
                          );
                          const auditEndDate = DateTime.fromFormat(
                            d.auditEndDate,
                            "MM/dd/yyyy"
                          );
                          if (
                            d.status === "Complete" ||
                            d.status === "Pending"
                          ) {
                            return null;
                          }
                          if (auditStartDate < now && auditEndDate > now) {
                            return "Current";
                          }
                        },
                        (d) => {
                          const auditEndDate = DateTime.fromFormat(
                            d.auditEndDate,
                            "MM/dd/yyyy"
                          );
                          if (d.status === "Complete") {
                            return null;
                          }
                          if (
                            auditEndDate &&
                            auditEndDate < now &&
                            d.status !== "Complete"
                          ) {
                            return "Past Due";
                          }
                        },
                      ]}
                      disabledTooltip={(d) => {
                        switch (d.name) {
                          case "Need to Start":
                            return "Audit Start Date column must be enabled to filter by this field";
                          case "Current":
                            return "Audit Start Date and Audit End Date column must be enabled to filter by this field";
                          case "Past Due":
                            return "Audit End Date column must be enabled to filter by this field";
                        }
                        return null;
                      }}
                      fieldMask={["Need to Start", "Current", "Past Due"]}
                      colors={["#0c8a4a", "#ee8219", "#d83648"]}
                    />
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </HGAccordion>

        <Row>
          <Col>
            <Card>
              <Card.Header>
                {TabElements.ColumnSortFilterUI}
                Audits
                <TabUI />
              </Card.Header>
              <ErrorBoundary>
                <Card.Body>{TabElements.TableUI}</Card.Body>
              </ErrorBoundary>
            </Card>
          </Col>
        </Row>
      </>
    )
  );
};
