export const getActionPlanTaskCompletionStats = (tasks) => {
  const activeTask = tasks?.filter((item) => item.task.archived !== true);
  if (activeTask?.length === 0 || !activeTask) {
    return -1;
  }
  const closedTasks = activeTask.filter(
    (taskItem) => taskItem.task.status === "Closed"
  );
  const percentage = (closedTasks.length / activeTask.length) * 100;
  if (closedTasks.length === 0) {
    return 0; // Return 0% if there are no closed tasks
  }
  return percentage;
};
